#product {

	.section-product-hero {
		height: 754px;
		width: 100%;

		@include mq(tablet-down) {
			height: calc(100vh - 61px);
		}
		
		.list-product-hero-images {
			height: 100%;
			list-style-type: none;
			margin: 0;

			.item-product-hero-image {
				background-position: center;
				background-size: cover;
				display: flex;
				height: 754px;
				margin: 0;
				width: 100vw !important;
			}

			.slick-dots {
				background-color: rgba(255, 255, 255, 0.5);
				position: absolute;
				bottom: 0;
				display: flex;
				width: 100%;

				li {
					cursor: pointer;
					flex: 1;
					flex-shrink: 0;
					height: 7px;
					margin: 0;
					padding: 0;

					@include mq(tablet-down) {
						height: 10px;
					}


					button {
						background: rgba($color-white, 0.8);
						border: none;
						border-radius: 0;
						text-decoration: none;
						text-indent: -99999px;
						width: 100%;
						padding: 0;
						height: 7px;
						transition: background .2s ease;
						outline: none;
						cursor: pointer;

						@include mq(tablet-down) {
							height: 10px;
						}
					}

					n mbhy8u
					&.slick-active button {
						background-color: $color-primary;
						opacity: 0.8;
					}

					&.slick-active button:hover {
						background-color: $color-primary;
						opacity: 0.8;
					}

					button:hover {
						background-color: $color-primary;
						opacity: 0.4;
					}
				}
			}

			.slick-list {
				height: 100%;
				padding: 0;
			}
		}
	}

	.section-product-intro {
		margin-top: 80px;

		@include mq(mobile) {
			margin-top: 40px;
		}

		.sub-content {
			@extend %container;
			margin-bottom: 50px;

			h2 {
				color: $color-text-title;
				font-size: 25px;
				font-weight: $font-weight-bold;
				letter-spacing: 0.6px;
				margin-bottom: 34px;
				max-width: 512px;

				@include mq(mobile) {
					font-size: 20px;
				}
			}

			p {
				font-size: 18px;
				font-weight: $font-weight-light;
				letter-spacing: 0.4px;
				line-height: 33px;
				max-width: 851px;

				@include mq(mobile) {
					font-size: 14px;
					line-height: 32px;
				}
			}
		}
	}

	.section-products {
		margin-bottom: 200px;

		@include mq(tablet-down) {
			margin-bottom: 0;
			overflow: hidden;
		}

		.sub-content {
			@extend %container;

			.list-products {
				display: flex;
				flex-wrap: wrap;
				list-style-type: none;
				margin: 70px 0 0 0;

				@include mq(tablet-down) {
					margin: 0 0 120px 0;
				}

				.item-product {
					cursor: pointer;
					margin: 0;
					overflow: hidden;
					position: relative;
					width: calc(100% / 3);

					@include mq(tablet-down) {
						width: 100%;
					}

					.sub-image {
						background-position: center;
						background-size: cover;
						background-repeat: no-repeat;
						height: 403px;

						@include mq(tablet-down) {
							height: 196px;
						}
					}

					.sub-link {
						height: 100%;
						left: 0;
						position: absolute;
						top: 0;
						width: 100%;

						&:hover {
							& ~ .item-info {
								svg {
									animation: arrow-anim-right 1s;
								}

								a {
									display: flex;
								}

								p {
									display: none;
								}
							}
						}
					}

					.item-info {
						background-color: #DBDBD7;
						max-height: 135px;
						margin-top: -135px;
						padding: 30px 40px;

						@include mq(tablet-down) {
							display: flex;
							flex-direction: column;
							height: 79px;
							justify-content: center;
							margin: 0;
							padding: 17px 25px;
							width: 100%;
						}

						h5 {
							align-items: center;
							color: #00303C;
							display: flex;
							font-size: 19px;
							font-weight: $font-weight-bold;
							letter-spacing: 0.8px;
							margin: 0;

							@include mq(tablet-down) {
								font-size: $font-size-med;
								margin-bottom: 0;
							}

							.btn-icon {
								display: flex;
								margin-left: auto;

								svg {
									color: #71898c;
								}
							}

							a {
								align-items: center;
								display: flex;
								font-size: 19px;
								font-weight: $font-weight-bold;
								letter-spacing: 1.8px;
								width: 100%;

								@include mq(tablet-down) {
									margin-top: 0px;
									font-size: 17px;
								}


								.btn {
									display: flex;
								}

								svg {
									color: #71898c;
									margin-left: 20px;
								}
							}
						}
					}
				}
			}
		}
	}
}