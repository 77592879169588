.header-main {
	align-items: center;
	background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
	display: flex;
	margin: 0 auto;
	opacity: 1;
	position: absolute;
	padding: 49px 0;
	width: 100%;
	z-index: 999;

	@include mq(tablet-down) {
		height: 61px;
		padding: 17px 0;
	}


	&.mod-white {
		align-items: center;
		background: $color-white;
		border-bottom: 2px solid #f6f6f6;
		display: flex;
		position: relative;
		width: 100%;

		.sub-container {
			nav ul.list-nav li > a {
				color: $color-primary;

				svg {
					color: $color-primary;
				}
			}

			.mob-hamburger .mob-bar {
				background: $color-primary;
			}
		}
	}

	&.sub-remove-margin {
		margin: 0 auto;
	}

	.sub-container {
		display: flex;
		padding-left: 50px;
		padding-right: 50px;
		width: 100%;

		@include mq(tablet-down) {
			padding: 0 17px;
		}


		.sub-logo {
			z-index: 999;

			a {
				display: block;
				z-index: 999;

				svg {
					color: $color-primary;
					height: 50px;
					width: 175px;
					z-index: 999;

					@include mq(tablet-down) {
						height: 50px;
						width: 192px;
					}
				}
			}
		}

		nav {
			align-items: center;
			display: flex;
			margin-left: auto;

			@include mq(tablet-down) {
				background: $color-white;
				display: block;
				height: 100vh;
				left: 0;
				overflow: scroll;
				position: absolute;
				top: 0;
				visibility: hidden;
				width: 100%;
			}


			ul.list-nav {
				@extend %list-default;

				@include mq(tablet-down) {
					display: none;
					flex-direction: column;
					height: auto;
					overflow: scroll;
					padding: 60px 20px;
					width: 100%;
				}

				&.mod-active {

					@include mq(mobile) {
						display: block;
					}
				}


				li {
					display: inline-block;
					margin: 0 8px;
					position: relative;

					@include mq(tablet-down) {
						clear: both;
						flex-direction: column;
						height: 100%;
						text-align: left;
						width: 100%;
					}


					&:first-child {
						display: none;

						@include mq(tablet-down) {
							display: block;
						}
					}

					&.mod-drop {
						&:hover {
							background: $color-white;

							a {
								color: $color-primary;
							}

							svg {
								color: $color-primary;
								transform: rotate(180deg);
							}
						}

						.mod-dropdown-active {
							svg {
								transform: rotate(-180deg);
							}
						}
					}

					& > a {
						align-items: center;
						color: $color-white;
						display: flex;
						font-size: 13px;
						font-weight: $font-weight-semibold;
						letter-spacing: 2px;
						padding: 14px;
						text-transform: uppercase;

						@include mq(tablet-down) {
							color: $color-primary;
							font-size: $font-size-standard;
							letter-spacing: 0.8px;
							padding: 16px 14px;
						}


						.icon-carot-down {
							color: $color-white;
							height: 8px;
							margin-left: 15px;
							transition: all 270ms ease-out;
							width: 10px;

							@include mq(tablet-down) {
								color: $color-primary;
							}
						}

						&:hover {
							& + .sub-dropdown-wrap {
								opacity: 1;
								visibility: visible;
								z-index: 999;
							}
						}
					}

					.sub-dropdown-wrap {
						background: $color-white;
						box-shadow: 2px 8px 14px -4px rgba(0, 0, 0, 0.12);
						left: 0;
						min-width: 237px;
						opacity: 0;
						position: absolute;
						top: 48px;
						visibility: hidden;
						z-index: 9999;

						@include mq(tablet-down) {
							box-shadow: none;
							max-height: 0;
							min-width: auto;
							opacity: 1;
							overflow: hidden;
							padding: 0 14px 0 14px;
							position: static;
							transition: all .3s ease;
							visibility: visible;
							z-index: 9;

							&.mod-active {
								display: block;
								max-height: 140px;
								padding: 0 14px 0 14px;
							}
						}


						&:hover {
							display: block;
							opacity: 1;
							visibility: visible;
							z-index: 999;
						}

						ul.list-sub-nav {
							@extend %list-default;

							background: $color-white;
							padding: 0 14px;
							position: relative;
							width: 100%;

							@include mq(tablet-down) {
								box-shadow: none;
								padding: 0;
							}


							& > li {
								width: 100%;

								&:first-child {
									display: block;
								}

								a {
									color: $color-primary;
									font-size: 13px;
									display: block;
									padding: 14px 0;
									text-transform: uppercase;

									@include mq(tablet-down) {
										padding: 12px 0;
									}


									&:hover {
										color: $color-primary;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.section-cookies {
	align-items: center;
	background: $color-primary;
	display: flex;
	padding: 20px 0;
	width: 100%;
	z-index: 999;

	@include mq(tablet-down) {
		height: auto;
		padding: 10px 0 20px;
	}


	&.mod-hide {
		display: none;
	}

	.sub-content {
		@extend %container-large;

		display: flex;

		@include mq(tablet-down) {
			flex-direction: column;
		}


		.sub-text {
			flex: 1;

			& > p {
				color: $color-white;
				font-size: $font-size-small;
				line-height: $line-height-base;
				margin-bottom: 0;

				@include mq(tablet-down) {
					color: rgba($color-white, 0.7);
				}


				a {
					color: $color-white;
					font-weight: $font-weight-semibold;

					@include mq(tablet-down) {
						color: rgba($color-white, 0.7);
					}
				}
			}
		}

		.sub-btns {
			align-items: center;
			display: flex;
			margin-left: 70px;

			@include mq(tablet-down) {
				margin-left: 0;
				margin-top: 20px;
			}


			.btn {
				background: none;
				border: 2px solid rgba($color-white, 0.25);
				color: $color-white;
				display: inline-block;
				font-size: $font-size-standard;
				font-family: $headings-font !important;
				padding: 5px 0;
				transition: background .3s ease;
				width: 130px;

				@include mq(tablet-down) {
					font-size: $font-size-small;
					padding: 4px 0;
					width: 115px;
				}


				&:first-of-type {
					margin-right: 10px;
				}

				&:hover {
					background: rgba($color-white, 0.25);
				}
			}
		}
	}
}

.mob-hamburger {
	@include mq(tablet-down) {
		cursor: pointer;
		display: flex;
		flex-direction: column;
		height: 20px;
		justify-content: center;
		overflow: hidden;
		position: absolute;
		right: 20px;
		top: 50%;
		transform: translateY(-50%);
		transition: width .3s ease;
		width: 23px;
		z-index: 999;

		.mob-bar {
			background: $color-white;
			border-radius: 20px;
			display: block;
			height: 2px;
			left: 0;
			margin-bottom: 8px;
			opacity: 1;
			position: absolute;
			transform: rotate(0deg);
			transition: .4s ease-in-out;
			width: 100%;
			z-index: 9999;
		}

		.mob-bar-1 {
			top: 0;
		}

		.mob-bar-2 {
			top: 8px;
		}

		.mob-bar-3 {
			margin-bottom: 0;
			top: 16px;
			width: 14px;
		}

		&.mod-active {
			width: 28px;

			.mob-bar {
				background: $color-primary;
			}

			.mob-bar-1 {
				top: 9px;
				transform: rotate(135deg);
			}

			.mob-bar-2 {
				left: -60px;
				opacity: 0;
			}

			.mob-bar-3 {
				transform: rotate(-135deg);
				top: 9px;
				width: 100%;
			}

			& + #nav {
				visibility: visible;
				width: 100%;
				z-index: 199;
			}
		}
	}
}
