#projects {
	.section-project-filters {
		border-bottom: 2px solid #f6f6f6;

		.sub-content {
			@extend %container;

			.list-filter-buttons {
				display: flex;
				list-style-type: none;
				margin: 0 auto;

				@include mq(tablet-down) {
					display: none;
					flex-direction: column;
				}


				.item-filter-button {
					color: #8D8A8A;
					cursor: pointer;
					font-size: 18px;
					font-weight: $font-weight-regular;
					letter-spacing: 0.8px;
					margin: 26px 60px 26px 0;

					@include mq(tablet-down) {
						font-size: 14px;
						margin: 10px 0;
					}


					.svg-icon {
						color: #8D8A8A;
						height: 10px;
						margin-left: 13px;
						width: 15px;
						-webkit-transform: scale(0.75);
						-ms-transform: scale(0.75);
						transform: scale(0.75);
						-webkit-transition: -webkit-transform .3s ease;
						transition: -webkit-transform .3s ease;
						-o-transition: transform .3s ease;
						transition: transform .3s ease;
						transition: transform .3s ease,-webkit-transform .3s ease;
					}
				}

				.item-filter-button.mod-hide-desktop {
					display: none;

					@include mq(tablet-down) {
						display: block;
					}
				}

				.item-filter-button.mod-hide-tablet-down {
					display: block;

					@include mq(tablet-down) {
						//display: none;
					}
				}
			}

			.list-filter-buttons-mobile {
				@extend .list-filter-buttons;

				display: none;

				@include mq(mobile) {
					display: flex;
					flex-direction: column;
				}
			}
		}
	}

	.section-projects {
		margin-bottom: 200px;

		@include mq(tablet-down) {
			margin-bottom: 0;
			overflow: hidden;
		}


		.sub-content {
			@extend %container;

			.list-projects {
				display: flex;
				flex-wrap: wrap;
				list-style-type: none;
				margin: 70px 0 0 0;

				@include mq(tablet-down) {
					margin: 33px 0 0 0;
				}


				.item-project {
					cursor: pointer;
					margin: 0;
					position: relative;
					width: calc(100% / 3);

					@include mq(tablet-down) {
						width: 100%;
					}


					.sub-image {
						background-position: center;
						background-size: cover;
						background-repeat: no-repeat;
						height: 403px;

						@include mq(tablet-down) {
							height: 218px;
						}
					}

					.sub-link {
						@include mq(tablet-down) {
							height: 100%;
							position: absolute;
							left: 0;
							top: 0;
							width: 100%;
							z-index: 999;

							&:hover ~ .item-info svg {
								animation: arrow-anim-right 1s;
							}
						}
					}

					.item-info {
						background-color: rgba(238, 236, 230, 0.85);
						margin-top: -135px;
						max-height: 135px;
						padding: 30px 40px;

						@include mq(tablet-down) {
							display: flex;
							flex-direction: column;
							height: 104px;
							justify-content: center;
							margin: 0;
							padding: 17px 25px;
							width: 100%;
						}


						p {
							color: #5B6464;
							font-size: 13px;
							letter-spacing: 1.3px;
							margin-bottom: 5px;
							text-transform: uppercase;

							@include mq(tablet-down) {
								color: #5B6464;
								font-size: 10px;
								letter-spacing: 1.3px;
								margin-bottom: 5px;
								text-transform: uppercase;
							}
						}

						h5 {
							align-items: center;
							color: #00303C;
							display: flex;
							font-size: 19px;
							font-weight: $font-weight-bold;
							letter-spacing: 0.8px;

							@include mq(tablet-down) {
								font-size: $font-size-med;
								margin-bottom: 0;
							}


							.btn-icon {
								display: flex;
								margin-left: auto;

								svg {
									color: #71898c;
								}
							}
						}
					}
				}

				.project-info-overlay {
					background-color: rgba(255, 255, 255, 0.8);
					display: none;
					height: 100%;
					margin: 0;
					position: absolute;
					width: 100%;

					@include mq(tablet-down) {
						display: none;
					}


					.item-overlay-info {
						display: flex;
						flex-direction: column;
						height: 100%;
						justify-content: space-between;

						p {
							color: $color-text-title;
							font-size: 16px;
							line-height: 30px;
							margin: 30px;
							width: 70%;
						}
					}

					.item-overlay-link {
						display: flex;
						flex-direction: column;
						height: 110px;
						justify-content: center;
						padding: 30px 40px;

						a {
							align-items: center;
							color: $color-text-title;
							font-size: 13px;
							font-weight: $font-weight-bold;
							letter-spacing: 1.63px;
							text-transform: uppercase;
						}
					}
				}

				.item-project:hover {
					.project-info-overlay {
						display: block;

						@include mq(tablet-down) {
							display: none;
						}
					}

					& > .sub-link {
						position: absolute;
						z-index: 999;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
					}

					.item-info {
						display: none;

						@include mq(tablet-down) {
							display: flex;
						}
					}
				}
			}

			.sub-button {
				background-color: $color-white;
				border: 1px solid #a7aba1;
				cursor: pointer;
				display: block;
				font-size: 12px;
				font-weight: $font-weight-bold;
				letter-spacing: 0.6px;
				margin: 95px auto 53px auto;
				padding: 15px;
				text-align: center;
				text-transform: uppercase;
				width: 204px;

				@include mq(tablet-down) {
					margin: 53px auto;
					width: 70%;
				}
			}
		}
	}
}
