#special-projects {
	.section-main-copy {
		margin-top: 60px;

		@include mq(tablet-down) {
			margin-top: 40px;
		}


		.sub-content {
			@extend %container;

			display: flex;

			@include mq(tablet-down) {
				flex-direction: column;
			}


			.sub-copy-wrapper {
				flex: 1;
				max-width: 613px;
				width: 100%;

				@extend %common-two-col-layout;
			}

			.sub-stats {
				@extend %stats;
			}
		}
	}

	.section-image-full {
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		height: 536px;
		margin-top: 150px;

		@include mq(tablet-down) {
			display: none;
		}
	}

	.section-case-study {
		background-color: $color-secondary;
		height: 600px;

		@include mq(tablet-down) {
			height: auto;
		}


		.sub-content {
			align-content: center;
			display: flex;
			height: 100%;

			@include mq(tablet-down) {
				flex-wrap: wrap;
				justify-content: unset;
			}


			.sub-main-copy {
				align-items: center;
				display: flex;
				flex: 1;
				justify-content: center;
				padding: 50px;

				.sub-copy {
					max-width: 627px;
					width: 100%;

					h2 {
						color: $color-text-title;
						font-size: 25px;
						font-weight: $font-weight-bold;
						letter-spacing: 0.6px;
						max-width: 512px;

						@include mq(tablet-down) {
							font-size: 20px;
						}
					}

					p {
						font-size: 16px;
						font-weight: $font-weight-regular;
						line-height: 33px;

						@include mq(tablet-down) {
							font-size: 14px;
							max-width: none;
						}


						strong {
							color: $color-text-title;
							font-weight: $font-weight-bold;
						}
					}

					a {
						font-size: 13px;
						font-weight: $font-weight-bold;
						letter-spacing: 1.8px;
						text-transform: uppercase;

						@include mq(tablet-down) {
							font-size: 11px;
						}
					}
				}
			}

			.sub-content-image {
				background-position: center;
				background-size: cover;
				background-repeat: no-repeat;
				max-width: 540px;
				width: 100%;

				@include mq(tablet-down) {
					max-width: none;
					width: 50%;
				}


				@include mq(mobile) {
					height: 288px;
					width: 100%;
				}
			}
		}

		&.mod-content-block-reverse {
			background-color: $color-white;

			.sub-content {
				flex-direction: row-reverse;

				.sub-main-copy {
					.sub-copy {
						max-width: 845px;
					}
				}

				.sub-content-image {
					@include mq(tablet-down) {
						background-size: cover;
					}
				}
			}
		}
	}
}
