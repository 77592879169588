#about {
	font-family: $font-standard;

	.section-about {
		margin-top: 60px;

		@include mq(tablet-down) {
			margin-top: 40px;
		}


		.sub-content {
			@extend %container;

			display: flex;

			@include mq(tablet-down) {
				flex-direction: column;
			}


			.sub-copy-wrapper {
				flex: 1;
				max-width: 613px;
				width: 100%;

				@extend %common-two-col-layout;
			}

			.sub-stats {
				@extend %stats;
			}
		}
	}

	.section-image-full {
		background-size: 101%;
		background-position: center;
		background-repeat: no-repeat;
		height: 536px;
		margin-top: 80px;

		@include mq(tablet-down) {
			background-size: cover;
			height: 387px;
			margin: 0;
		}
	}

	.section-meet-team {
		margin: 155px auto 140px auto;

		@include mq(tablet-down) {
			margin: 80px auto;
		}


		.sub-content {
			@extend %container;

			.sub-title {
				h2 {
					color: $color-text-title;
					font-size: 25px;
					font-weight: $font-weight-bold;
					margin-bottom: 55px;

					@include mq(tablet-down) {
						display: flex;
						flex-direction: column-reverse;
						font-size: 18px;
					}


					span {
						color: #5B6464;
						font-size: 13px;
						font-weight: $font-weight-regular;
						letter-spacing: 1.6px;
						margin-left: 30px;
						text-transform: uppercase;

						@include mq(tablet-down) {
							font-size: 10px;
							margin: 0 0 8px 0;
						}
					}
				}
			}

			.sub-team {
				.list-team {
					display: flex;
					flex-wrap: wrap;
					list-style-type: none;
					margin: 0;
					position: relative;

					.item-team {
						background-position: center;
						background-repeat: no-repeat;
						background-size: cover;
						height: 240px;
						margin: 0 0 30px 0;
						overflow: hidden;
						width: calc(100% / 4);

						@include mq(tablet-down) {
							height: 221px;
							margin-bottom: 79px;
							overflow: unset;
							position: relative;
							width: 100%;
						}


						.team-info {
							background-color: $color-secondary;
							bottom: -247px;
							padding: 20px 25px;
							position: relative;

							@include mq(tablet-down) {
								bottom: -79px;
								height: 79px;
								margin: 0;
								padding: 17px 25px;
								position: absolute;
								width: 100%;
							}


							p {
								color: #5B6464;
								font-size: 13px;
								font-weight: $font-weight-regular;
								letter-spacing: 1.6px;
								margin-bottom: 5px;
								text-transform: uppercase;

								@include mq(tablet-down) {
									font-size: 10px;
								}
							}

							h5 {
								font-size: 20px;
								font-weight: $font-weight-bold;
								margin: 0;

								@include mq(tablet-down) {
									font-size: 17px;
								}
							}
						}
					}

					.item-team:hover {
						.team-info {
							bottom: -147px;

							@include mq(tablet-down) {
								bottom: -79px;
							}
						}
					}
				}
			}
		}
	}

	.section-content-block {
		background-color: $color-secondary;

		.sub-content {
			display: flex;

			@include mq(tablet-down) {
				flex-direction: column;
				justify-content: unset;
			}


			.sub-main-copy {
				flex: 1;
				min-height: 600px;
				display: flex;
				flex-direction: column;
				justify-content: center;

				@include mq(tablet-down) {
					min-height: auto;
					padding: 88px 21px;
				}


				.sub-copy {
					padding: 70px 120px;

					@include mq(tablet-down) {
						padding: 0;
					}


					h2 {
						color: $color-text-title;
						font-size: 25px;
						font-weight: $font-weight-bold;
						letter-spacing: 0.6px;
						max-width: 512px;

						@include mq(tablet-down) {
							font-size: 20px;
							max-width: none;
						}
					}

					p {
						font-size: 16px;
						font-weight: $font-weight-regular;
						line-height: 33px;

						@include mq(tablet-down) {
							font-size: 14px;
							max-width: none;
						}
					}

					a {
						align-items: center;
						display: flex;
						font-size: 13px;
						font-weight: $font-weight-bold;
						letter-spacing: 1.8px;
						text-transform: uppercase;

						@include mq(tablet-down) {
							font-size: 11px;
						}


						.btn {
							display: flex;
							margin-left: 20px;

							svg {
								color: #71898c;
							}
						}

						&:hover {
							svg {
								animation: arrow-anim-right 1s;
							}
						}
					}
				}
			}

			.sub-content-image {
				background-position: center;
				background-size: cover;
				background-repeat: no-repeat;
				max-width: 540px;
				width: 100%;

				@include mq(tablet-down) {
					height: 260px;
					max-width: none;
				}
			}
		}

		&.mod-content-block-reverse {
			background-color: $color-white;

			.sub-content {
				flex-direction: row-reverse;

				@include mq(tablet-down) {
					flex-direction: column;
				}


				.sub-content-image {
					@include mq(tablet-down) {
						background-size: unset;
						order: 1;
					}
				}
			}
		}
	}
}
