// Define placeholders within here to be used within further sass.
%container {
	width: $container-width;
	max-width: $container-max-width;
	margin: 0 auto;

	// Example of how to extend container. Used via `@extend %container-large`
	&-large {
		@extend %container;

		max-width: $container-max-width-large;
	}

	&-small {
		@extend %container;

		max-width: $container-max-width-small;
	}

	@include mq(mobile) {
		width: $container-width-mobile;
		max-width: $container-max-width-mobile;
		margin: 0 auto;
	}
}

%clearfix {
	zoom: 1;

	&:after {
		clear: both;
	}

	&:before,
	&:after {
		content: "";
		display: table;
	}
}

%noselect {
	-webkit-touch-callout: none;
	user-select: none;
}

%nofocus {
	&:focus {
		outline-style: none;
		box-shadow: none;
	}
}

%list-default {
	margin: 0;
	padding: 0;
	list-style: none;

	& > li {
		margin: 0;
		padding: 0;
	}
}

%animation-right {
	overflow: hidden;

	&:hover svg {
		animation: arrow-anim-right 1s;
		color: $color-primary;
	}
}

%stats {
	border-top: 2px solid #f6f6f6;
	margin-left: 150px;
	max-width: 430px;
	padding-top: 61px;
	width: 100%;

	@include mq(tablet-down) {
		margin: 20px 0 40px 0;
		padding-top: 20px;
		width: 100%;
	}


	.list-stats {
		list-style-type: none;
		margin-left: 0;

		.item-stat {
			margin-left: 0;
			margin-bottom: 70px;

			@include mq(tablet-down) {
				margin-bottom: 50px;
			}


			h1 {
				color: #50A5A7;
				font-size: 42px;
				font-weight: $font-weight-thin;
				line-height: 50px;
				margin-bottom: 10px;

				@include mq(tablet-down) {
					line-height: 42px;
					font-size: 40px;
					margin-bottom: 10px;
				}
			}

			h4 {
				color: $color-text-title;
				font-size: 18px;
				font-weight: $font-weight-light;
				width: 332px;

				@include mq(tablet-down) {
					color: #5B6464;
					font-size: 17px;
					width: 80%;
				}
			}
		}
	}
}

%common-two-col-layout {
	h2 {
		color: $color-text-title;
		font-size: 25px;
		font-weight: $font-weight-bold;
		letter-spacing: 0.6px;
		margin-bottom: 34px;
		max-width: 512px;

		@include mq(tablet-down) {
			font-size: 20px;
		}
	}

	.sub-intro-copy {
		p {
			font-size: 19px;
			line-height: 32px;

			@include mq(tablet-down) {
				font-size: $font-size-standard;
			}


			strong {
				font-weight: $font-weight-regular;
			}
		}
	}

	.sub-main-copy {
		p {
			font-size: $font-size-med;
			font-weight: $font-weight-light;
			letter-spacing: 0.4px;
			line-height: 32px;
			max-width: 612px;

			@include mq(tablet-down) {
				font-size: 14px;
			}


			strong {
				font-weight: $font-weight-regular;
			}
		}
	}
}

%common-left-50-wide {
	flex: 1;
	margin-right: 117px;

	@include mq(tablet-down) {
		margin-right: 0;
	}


	h2 {
		font-size: 25px;
		font-weight: $font-weight-bold;
	}

	.sub-intro-copy {
		margin-bottom: 40px;

		p {
			font-size: 19px;
			line-height: 33px;

			@include mq(tablet-down) {
				font-size: $font-size-standard;
			}


			strong {
				font-weight: $font-weight-regular;
			}
		}
	}

	.sub-main-copy {
		@include mq(tablet-down) {
			color: $color-black;
			width: 100%;
		}


		h2 {
			color: $color-text-title;
			font-size: 25px;
			font-weight: bold;
			letter-spacing: 0.6px;
			margin-bottom: 40px;
			max-width: 512px;

			@include mq(tablet-down) {
				font-size: 20px;
				margin-bottom: 30px;
			}
		}

		p {
			font-size: $font-size-med;
			letter-spacing: 0.4px;
			line-height: 33px;
			max-width: 612px;

			@include mq(tablet-down) {
				font-size: $font-size-standard;
			}
		}
	}
}
