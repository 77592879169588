#corporate-information {
	font-family: $font-standard;

	.section-corp-and-soc-resp {
		margin-top: 60px;

		@include mq(mobile) {
			margin-top: 40px;
		}

		.sub-content {
			@extend %container;

			.sub-sub-copy-wrapper {
				margin-bottom: 50px;

				h2 {
					color: $color-text-title;
					font-size: 25px;
					font-weight: $font-weight-bold;
					letter-spacing: 0.6px;
					margin-bottom: 34px;
					max-width: 512px;

					@include mq(mobile) {
						font-size: 20px;
					}
				}

				p {
					font-size: 18px;
					font-weight: $font-weight-light;
					letter-spacing: 0.4px;
					line-height: 33px;
					max-width: 851px;

					@include mq(mobile) {
						font-size: 14px;
						line-height: 32px;
					}
				}
			}

			.sub-main-copy-wrapper {
				display: flex;
				justify-content: space-between;

				@include mq(mobile) {
					flex-wrap: wrap;
				}


				.main-copy-left-col, .main-copy-right-col {
					font-size: 18px;
					font-weight: $font-weight-light;
					letter-spacing: 0.4px;
					line-height: 33px;
					margin: 0;
					max-width: 851px;
					width: 45%;

					@include mq(mobile) {
						font-size: 14px;
						line-height: 32px;
						width: 100%;
					}
				}
			}
		}
	}

	.section-image-full {
		background-size: 101%;
		background-position: 50% 70%;
		background-repeat: no-repeat;
		height: 609px;
		margin-top: 90px;

		@include mq(mobile) {
			background-size: cover;
			height: 387px;
			margin-top: 60px;
		}
	}

	.section-health-and-environment {
		margin-top: 120px;

		@include mq(mobile) {
			margin-top: 60px;
		}


		.sub-content {
			@extend %container;

			.sub-sub-copy-wrapper {
				margin-bottom: 50px;

				h2 {
					color: $color-text-title;
					font-size: 25px;
					font-weight: $font-weight-bold;
					letter-spacing: 0.6px;
					margin-bottom: 34px;
					max-width: 512px;

					@include mq(mobile) {
						font-size: 20px;
					}
				}

				.sub-wrap {
					display: flex;

					p {
						font-size: 18px;
						font-weight: $font-weight-light;
						letter-spacing: 0.4px;
						line-height: 33px;
						max-width: 851px;

						@include mq(mobile) {
							font-size: 14px;
							line-height: 32px;
						}
					}

					.sub-logos {
						align-items: flex-start;
						display: flex;
						margin-left: 70px;
					}
				}
			}

			.sub-main-copy-wrapper {
				display: flex;
				justify-content: space-between;

				@include mq(mobile) {
					flex-wrap: wrap;
				}


				.main-copy-left-col, .main-copy-right-col {
					font-size: 18px;
					font-weight: $font-weight-light;
					letter-spacing: 0.4px;
					line-height: 33px;
					max-width: 851px;
					width: 45%;

					@include mq(mobile) {
						font-size: 14px;
						line-height: 32px;
						width: 100%;
					}
				}
			}
		}
	}

	.section-covid-block {
		background-color: $color-secondary;
		margin-top: 120px;

		@include mq(mobile) {
			margin-top: 40px;
		}


		.sub-content {
			@extend %container;

			padding: 80px 0;

			@include mq(mobile) {
				padding: 60px 0;
			}


			h2 {
				color: $color-text-title;
				font-size: 25px;
				font-weight: $font-weight-bold;
				letter-spacing: 0.6px;
				margin-bottom: 34px;
				max-width: 512px;

				@include mq(mobile) {
					font-size: 20px;
				}
			}

			.sub-main-copy-wrapper {
				display: flex;
				justify-content: space-between;

				@include mq(mobile) {
					flex-wrap: wrap;
				}


				.main-copy-left-col, .main-copy-right-col {
					font-size: 18px;
					font-weight: $font-weight-light;
					letter-spacing: 0.4px;
					line-height: 33px;
					max-width: 851px;
					width: 45%;

					@include mq(mobile) {
						font-size: 14px;
						line-height: 32px;
						width: 100%;
					}


					p {
						margin: 0;
					}
				}

				.main-copy-left-col {
					@include mq(mobile) {
						margin-bottom: 20px;
					}
				}
			}
		}
	}

	.section-sustainable-constuct {
		margin-top: 120px;

		@include mq(mobile) {
			margin-top: 60px;
		}


		.sub-content {
			@extend %container;

			display: flex;
			justify-content: space-between;

			@include mq(mobile) {
				flex-wrap: wrap;
			}


			.main-copy-left-col, .main-copy-right-col {
				max-width: 851px;
				width: 45%;

				@include mq(mobile) {
					width: 100%;
				}
			}

			.main-copy-left-col {
				h2 {
					color: $color-text-title;
					font-size: 25px;
					font-weight: $font-weight-bold;
					letter-spacing: 0.6px;
					margin-bottom: 34px;
					max-width: 512px;

					@include mq(mobile) {
						font-size: 20px;
					}
				}

				p {
					font-size: 18px;
					font-weight: $font-weight-light;
					letter-spacing: 0.4px;
					line-height: 33px;
					max-width: 851px;

					@include mq(mobile) {
						font-size: 14px;
						line-height: 32px;
						margin-bottom: 5px;
					}
				}
			}

			.main-copy-right-col {
				.main-copy-image {
					background-position: center;
					background-size: cover;
					background-repeat: no-repeat;
					height: 461px;
					margin-top: 8px;

					@include mq(mobile) {
						height: 261px;
						width: 100%;
					}
				}
			}
		}
	}

	.section-quality-block {
		margin-top: 120px;
		background-color: $color-secondary;

		@include mq(mobile) {
			margin-top: 60px;
		}


		.sub-content {
			display: flex;

			@include mq(mobile) {
				flex-wrap: wrap;
				justify-content: unset;
			}


			.sub-main-copy {
				padding: 120px;
				width: calc((100% / 3) * 2);

				@include mq(mobile) {
					height: 100%;
					padding: 60px 15px;
					width: 100%;
				}


				h2 {
					color: $color-text-title;
					font-size: 25px;
					font-weight: $font-weight-bold;
					letter-spacing: 0.6px;
					max-width: 512px;

					@include mq(mobile) {
						font-size: 20px;
					}
				}

				p {
					font-size: 18px;
					font-weight: $font-weight-regular;
					line-height: 33px;
					max-width: 60%;

					@include mq(mobile) {
						font-size: 14px;
						max-width: unset;
					}


					strong {
						color: $color-text-title;
						font-weight: $font-weight-bold;
					}
				}

				a {
					font-size: 16px;
					font-weight: $font-weight-bold;
					letter-spacing: 1.8px;

					@include mq(mobile) {
						font-size: 11px;
					}
				}
			}

			.sub-content-image {
				background-position: center;
				background-size: cover;
				background-repeat: no-repeat;
				width: calc(100% / 3);

				@include mq(mobile) {
					height: 288px;
					width: 100%;
				}
			}
		}
	}
}
