/* COLOURS */
$color-black: #000000;
$color-white: #ffffff;

$color-body: $color-white;

$color-neutral: #F5F2F5;
$color-neutral-dark: #607D8B;

$color-borders: #aaaaaa;
$color-borders-light: #c8c8c8;

$color-text: #424242;
$color-text-light: #959595;
$color-text-title: #0A2240;

$color-headings: #263238;

$color-error: #ff0000;

$color-primary: #0A2240;
$color-secondary: #DBDBD7;

$color-buttons: $color-primary;

$color-link: $color-primary;
$color-link-hover: lighten($color-link, 5%);
$color-link-active: $color-link-hover;

$color-validation-error: #cd0000;
$color-validation-success: #4eb73c;

/* FONTS */
$font-standard: proxima-nova, sans-serif, Arial, Helvetica, Sans-serif;

$font-base: 14;

$font-size-base: $font-base * 1px;
$font-size-standard: 14 / $font-base * 1rem;
$font-size-tiny: 10 / $font-base * 1rem;
$font-size-small: 12 / $font-base * 1rem;
$font-size-med: 16 / $font-base * 1rem;
$font-size-large: 18 / $font-base * 1rem;
$font-size-larger: 21 / $font-base * 1rem;
$font-size-largest: 30 / $font-base * 1rem;

$font-size-h1: 36 / $font-base * 1rem;
$font-size-h2: 30 / $font-base * 1rem;
$font-size-h3: 24 / $font-base * 1rem;
$font-size-h4: $font-size-large;
$font-size-h5: $font-size-med;
$font-size-h6: $font-size-standard;

$font-size-hero-standard: $font-size-larger;
$font-size-hero-small: 20 / $font-base * 1rem;
$font-size-hero-med: 50 / $font-base * 1rem;
$font-size-hero-large: 65 / $font-base * 1rem;

$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$headings-font: $font-standard;
$headings-font-weight: $font-weight-light;
$headings-color: $color-headings;
$headings-line-height: 1.4;

$line-height-base: 1.55;
$line-height-large: 2;

$radius-button: 5px;
$padding-button: 15px;
$text-size-button: $font-size-standard;

/* STRUCTURE */
$viewport-width: 100%;
$container-width: 90%;
$container-max-width: 1152px;
$container-max-width-small: 900px;
$container-max-width-large: 1300px;

/* MOBILE STRUCTURE */
$container-width-mobile: 100%;
$container-gutter-mobile: 30px;
$container-max-width-mobile: calc(100% - #{$container-gutter-mobile});

/* MEDIA QUERY */
$mq-desktop-size: $container-max-width;
$mq-tablet-size: 1024px;
$mq-mobile-size: 768px;
