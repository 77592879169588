#home {
	overflow-x: hidden;

	.header-main .sub-logo svg {
		color: $color-white;
	}

	.section-hero-gallery {
		height: 100vh;
		width: 100%;

		.list-home-hero-images {
			margin: 0;
			height: 100%;

			.item-home-hero-image {
				background-position: center;
				background-size: cover;
				display: flex;
				height: 100vh;
				margin: 0;

				.copy {
					margin-top: 20px;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 100%;

					@include mq(mobile) {
						padding: 0 20px;
					}
					

					h1, h2 {
						color: $color-white;
						line-height: 32px;
						text-align: center;
					}

					h1 {
						font-size: 42px;
						font-weight: 600;
						margin-bottom: 10px;
						
						@include mq(mobile) {
							font-size: 34px;
						}
						
					}

					h2 {
						font-size: 26px;
						font-weight: 400;

						@include mq(mobile) {
							font-size: 20px;
						}
						
					}
				}
			}

			.slick-list {
				padding: 0;
				height: 100%;
			}
		}
	}

	.section-home-projects {
		margin: 120px 0;

		@include mq(mobile) {
			margin: 60px 0 0 0;
		}

		.sub-content {
			@extend %container;

			position: relative;

			@include mq(tablet-down) {
				overflow: hidden;
			}


			div {
				overflow: unset;
			}

			h5 {
				align-items: center;
				color: $color-primary;
				display: flex;
				font-size: 20px;
				font-style: normal;
				font-weight: $font-weight-semibold;
				letter-spacing: 1.6px;
				margin-bottom: 20px;

				@include mq(tablet-down) {
					font-size: 18px;
					margin-bottom: 13px;
				}


				.btn {
					margin-left: auto;

					svg {
						color: #71898c;
					}
				}
			}

			.list-home-projects {
				margin: 0;
				list-style-type: none;

				@include mq(tablet-down) {
					display: flex;
					flex-direction: column;
					flex-wrap: wrap;
				}


				.slick-prev, .slick-next {
					color: $color-primary;;
					cursor: pointer;
					width: 24px;
					position: absolute;
					top: -39px;
				}

				.slick-next {
					left: 150px;
				}

				.slick-prev {
					left: 110px;
				}

				.slick-disabled {
					cursor: default;
					opacity: 0.5;
				}

				.slick-slide {
					width: 395px;
				}

				.item-project {
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
					height: 277px;
					margin: 0;
					position: relative;

					@include mq(tablet-down) {
						background-size: cover;
						background-position: center;
						background-repeat: no-repeat;

						&:nth-child(n+5) {
							display: none;
						}
					}


					.sub-link {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						z-index: 99;
					}

					.item-information-overlay {
						background-color: $color-primary;
						display: none;
						flex-direction: column;
						height: 277px;
						margin: 0;
						opacity: 0.8;
						padding: 30px 40px;
						position: absolute;
						width: 100%;

						@include mq(tablet-down) {
							background: $color-secondary;
							bottom: 0;
							display: flex !important;
							height: 104px;
							justify-content: center;
							opacity: 1;
							padding: 20px 25px;
						}


						.project-info-sub-title {
							color: $color-white;
							font-size: 13px;
							letter-spacing: 1.3px;
							margin-bottom: 5px;
							text-transform: uppercase;

							@include mq(tablet-down) {
								color: #5B6464;
								font-size: 10px;
								margin-bottom: 0;
							}
						}

						p {
							justify-self: flex-end;
							color: $color-white;
							font-size: $font-size-med;
							line-height: 29px;

							@include mq(tablet-down) {
								line-height: $line-height-base;

								&:not(.project-info-sub-title) {
									display: none;
								}
							}
						}

						h5 {
							color: $color-white;
							font-size: 19px;
							font-weight: $font-weight-bold;
							letter-spacing: 0.8px;

							@include mq(tablet-down) {
								align-items: flex-start;
								color: $color-primary;
								font-size: 17px;
								margin-bottom: 0;
							}


							img {
								float: right;
								margin-top: 7px;
							}
						}
					}

					.mod-show {
						display: flex;

						svg {
							animation: arrow-anim-right 1s;
						}
					}
				}
			}

			.sub-button-mobile {
				display: none;

				@include mq(tablet-down) {
					border: 1px solid #a7aba1;
					display: block;
					font-size: 12px;
					font-weight: $font-weight-bold;
					letter-spacing: 0.6px;
					margin: 53px auto;
					padding: 15px;
					text-align: center;
					text-transform: uppercase;
					width: 70%;
				}
			}
		}
	}

	.section-main-copy {
		margin-bottom: 145px;

		@include mq(tablet-down) {
			margin-bottom: 75px;
		}


		.sub-content {
			@extend %container;

			display: flex;

			@include mq(tablet-down) {
				flex-direction: column-reverse;
			}


			.sub-content-copy {
				width: calc(100% - 42.45%);

				@include mq(tablet-down) {
					margin-top: 50px;
					width: 100%;
				}


				a {
					align-items: center;
					display: flex;
					font-size: 13px;
					font-weight: $font-weight-bold;
					letter-spacing: 1.8px;
					text-transform: uppercase;

					svg {
						color: #71898c;
						margin-left: 20px;
					}
				}

				h2 {
					color: $color-text-title;
					font-size: 25px;
					font-weight: $font-weight-bold;
					letter-spacing: 0.6px;
					margin-bottom: 36px;
					max-width: 512px;
					width: 100%;

					@include mq(tablet-down) {
						font-size: 21px;
						margin-bottom: 26px;
					}
				}

				.sub-main-copy {
					margin-bottom: 71px;

					@include mq(tablet-down) {
						margin-bottom: 40px;
						width: 90%;
					}


					p {
						font-size: 16px;
						line-height: 33px;
						max-width: 512px;

						@include mq(tablet-down) {
							font-size: 14px;
							font-weight: $font-weight-light;
						}


						strong {
							font-size: 18px;
							font-weight: $font-weight-regular;
						}
					}
				}
			}

			.sub-content-image {
				background-position: center;
				background-size: cover;
				background-repeat: no-repeat;
				width: 42.45%;
				height: 461px;

				@include mq(tablet-down) {
					height: 261px;
					width: 100%;
				}
			}
		}
	}

	.section-testimonials {
		.sub-content {
			display: flex;

			@include mq(tablet-down) {
				flex-direction: column-reverse;
			}


			.sub-testimonials-image {
				background-position: center;
				background-size: cover;
				background-repeat: no-repeat;
				width: calc(100% / 3);

				@include mq(tablet-down) {
					height: 288px;
					width: 100%;
				}
			}

			.sub-testimonials {
				background-color: $color-secondary;
				height: 461px;
				padding: 120px;
				width: calc((100% / 3) * 2);

				@include mq(tablet-down) {
					height: 100%;
					padding: 55px 15px;
					width: 100%;
				}


				.list-testimonials {
					list-style-type: none;

					@include mq(tablet-down) {
						margin: 0;
					}


					li.item-testimonial {
						@include mq(tablet-down) {
							margin: 0;
						}
					}

					h4 {
						color: $color-text-title;
						font-size: 25px;
						font-weight: $font-weight-bold;
						letter-spacing: 0.6px;
						margin-bottom: 25px;
					}

					p {
						font-size: 18px;
						line-height: 33px;
						width: 95%;

						@include mq(tablet-down) {
							font-size: 14px;
						}
					}

					.btn {
						align-items: center;
						display: flex;
						font-size: 13px;
						font-weight: $font-weight-bold;
						letter-spacing: 1.8px;
						margin-top: 30px;
						text-transform: uppercase;

						svg {
							color: #71898c;
							margin-left: 20px;
						}
					}

					.slick-dots {
						display: flex;
						flex-direction: column;
						position: absolute;
						right: 0;
						top: 0;
						width: 0;

						@include mq(tablet-down) {
							top: 40%;
						}


						li {
							margin-bottom: 18px;

							button {
								height: 2px;
								outline: none;
								width: 13px;
							}
						}

						.slick-active {
							background-color: $color-primary;
						}
					}

					.testimonial-name {
						color: $color-text-title;
						font-size: 11px;
						font-style: unset;
						font-weight: $font-weight-bold;
						letter-spacing: 1.8px;
						text-transform: uppercase;
					}
				}
			}
		}
	}
}
