.footer-main {
	background: $color-primary;
	color: $color-white;
	display: flex;
	padding: 63px 0;
	z-index: 1;

	@include mq(tablet-down) {
		padding: 40px 0;
	}


	.sub-content-wrapper {
		@extend %container;

		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		@include mq(tablet-down) {
			flex-direction: column;
		}


		.sub-content-left, .sub-content-right {
			display: flex;

			@include mq(tablet-down) {
				flex-direction: column;
				flex: 1;
			}


			.logo-telephone-wrapper {
				display: flex;

				@include mq(tablet-down) {
					align-items: center;
					flex-direction: column;
				}


				img {
					height: 93px;

					@include mq(tablet-down) {
						margin-top: 20px;
						order: 1;
					}
				}

				ul.list-footer-contact {
					margin: 0 0 0 36px;
					list-style-type: none;

					@include mq(tablet-down) {
						margin: 0;
						order: 0;
					}


					& > li {
						margin-left: 0;

						@include mq(tablet-down) {
							justify-content: center;
							display: flex;
						}


						a {
							color: #fff;
							display: flex;
							font-size: 19px;
							letter-spacing: 1.2px;
							margin-bottom: 10px;

							@include mq(tablet-down) {
								font-size: 17px;
							}


							span {
								display: block;
								font-weight: $font-weight-light;
								margin-right: 9px;
								width: 15px;
							}
						}
					}
				}
			}

			ul.list-social-links {
				@extend %list-default;

				display: flex;
				margin-bottom: 30px;

				@include mq(tablet-down) {
					display: none;
				}


				& > li {
					margin-right: 20px;

					a {
						color: $color-white;
						display: block;
						height: 100%;

						svg {
							color: $color-white;
							height: 24px;
							width: 34px;

							&.icon-instagram {
								width: 25px;
							}

							&.icon-facebook {
								width: 14px;
							}
						}
					}

					&:last-child {
						margin-right: 0;
					}
				}
			}

			a.btn {
				border: 0.5px solid #76777A;
				border-radius: 0;
				font-size: 13px;
				font-weight: $font-weight-bold;
				letter-spacing: 2px;
				justify-content: center;
				margin-bottom: 32px;
				text-align: center;
				text-transform: uppercase;
			}
		}

		.sub-content-left {
			width: 50%;

			@include mq(tablet-down) {
				order: 1;
				width: 100%;
			}
		}

		.sub-content-right {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			width: 50%;

			@include mq(tablet-down) {
				align-items: center;
				order: 0;
				width: 100%;
			}
		}

		.sub-footer-links {
			display: flex;
			justify-content: space-between;
			width: 100%;

			@include mq(tablet-down) {
				flex-direction: column;
				order: 2;
			}


			.list-footer-links-left, .list-footer-links-right {
				@extend %list-default;

				display: flex;
				list-style-type: none;
				width: 100%;

				@include mq(tablet-down) {
					margin-top: 28px;
					text-align: center;
				}


				& > li {
					font-size: 12px;
					opacity: 0.4;

					@include mq(tablet-down) {
						display: inline-block;
					}


					&:after {
						content: '|';
						color: $color-white;
						margin: 0 8px 0 10px;
					}

					a {
						color: $color-white;
					}

					&:last-child:after {
						content: none;
					}
				}
			}

			.list-footer-links-left {
				@include mq(tablet-down) {
					text-align: center;

					li {
						width: 100%;
					}
				}
			}

			.list-footer-links-right {
				justify-content: flex-end;

				@include mq(tablet-down) {
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					padding: 0 50px;

					li:nth-child(3):after {
						content: none;
					}
				}
			}
		}
	}
}
