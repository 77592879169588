.svg-icon {
	color: #ff99f8; // Default colour to stand out
	display: inline-block;
	vertical-align: middle;

	&.link-arrow {
		height: 15px;
		width: 30px;
	}

	&.icon-logo {
		height: 48px;
	}

	&.icon-at {
		height: 18px;
		width: 18px;
		color: $color-link;
	}

	&.icon-phone {
		height: 18px;
		width: 18px;
		color: $color-link;
	}

	// Social

	&.icon-social {
		height: 25px;
		width: 24px;
		color: $color-text-light;
	}

	&.icon-twitter {
		height: 24px;
		width: 34px;
		color: $color-neutral;

		&:hover {
			color: $color-primary;
		}
	}

	&.icon-x {
		fill: $color-secondary;
		height: 40px;
		width: 40px;

		&:hover {
			color: $color-primary;
		}
	}

	&.icon-linkedin {
		height: 23px;
		width: 24px;
		color: $color-neutral;

		&:hover {
			color: $color-primary;
		}
	}

	&.icon-linkedin-alt {
		height: 24px;
		width: 24px;
		color: $color-neutral;

		&:hover {
			color: $color-primary;
		}
	}

	&.icon-facebook {
		height: 26px;
		width: 13px;
		color: $color-neutral;

		&:hover {
			color: $color-primary;
		}
	}

	&.icon-pinterest {
		height: 27px;
		width: 21px;
		color: $color-neutral;

		&:hover {
			color: $color-primary;
		}
	}
}
