#process {
	.section-hero {
		align-items: center;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		display: flex;
		height: 319px;
		justify-content: center;
		position: relative;
		text-align: center;

		@include mq(tablet-down) {
			height: 250px;

			&:after {
				content: '';
				background: transparent;
				border: 1px solid $color-white;
				border-left: none;
				border-top: none;
				bottom: 40px;
				display: block;
				height: 12px;
				width: 12px;
				left: 50%;
				position: absolute;
				transform: rotate(45deg) translateX(-50%);
			}
		}


		&:before {
			background: rgba($color-black, 0.72);
			content: '';
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}

		h1 {
			color: $color-white;
			font-size: 55px;
			font-weight: $font-weight-semibold;
			margin-bottom: 8px;
			position: relative;
			z-index: 99;

			@include mq(tablet-down) {
				font-size: 25px;
			}
		}

		h2 {
			color: $color-white;
			font-size: $font-size-large;
			position: relative;
			z-index: 99;

			@include mq(tablet-down) {
				font-size: $font-size-med;
				max-width: 232px;
				width: 100%;
			}
		}
	}

	.section-process {
		padding: 75px 0 200px;
		background: $color-white;
		position: relative;

		@include mq(tablet-down) {
			padding: 30px 0;
		}


		.sub-background {
			width: 100%;
			height: 100%;
			opacity: 0.1;
			background-size: cover;
			background-attachment: fixed;
			position: absolute;
			top: 0;
			left: 0;
		}

		.sub-copy {
			@extend %container;

			max-width: 760px !important;
			margin-bottom: 150px;
			text-align: center;
			color: $color-white;

			@include mq(tablet-down) {
				margin-bottom: 70px;
				font-size: $font-size-med;
			}


			@include mq(mobile) {
				margin-bottom: 50px;
			}


			h2 {
				font-size: $font-size-h1 / $font-size-larger + em;
				font-weight: $font-weight-bold;
				margin-bottom: 30px;
				color: $color-white;
			}
		}

		.sub-process {
			@extend %container;

			max-width: 1050px !important;

			@include mq(mobile) {
				padding: 0 30px;
			}


			ul.list-process {
				@extend %list-default;

				display: flex;
				flex-wrap: wrap;
				position: relative;
				align-items: center;

				&:before {
					background: $color-black;
					content: "";
					height: calc(100% - 195px);
					left: 50%;
					position: absolute;
					top: -76px;
					width: 1px;

					@include mq(tablet-down) {
						display: none;
					}
				}

				&:after {
					background: $color-white;
					content: "";
					height: 72px;
					left: 50%;
					position: absolute;
					top: -147px;
					width: 1px;

					@include mq(tablet-down) {
						display: none;
					}
				}

				& > .item-marker {
					background: $color-white;
					border-radius: 50%;
					border: 1px solid $color-black;
					content: "";
					height: 30px;
					margin: auto;
					position: relative;
					width: 30px;

					@include mq(tablet-down) {
						display: none;
					}
				}

				li.item-process {
					flex-basis: calc(50% - 75px);
					flex-grow: 0;
					flex-shrink: 0;
					margin-bottom: 33px;
					position: relative;

					@include mq(tablet-down) {
						flex-basis: 100%;
						margin-bottom: 50px;

						&:after {
							content: '';
							background: transparent;
							border: 1px solid $color-primary;
							border-left: none;
							border-top: none;
							bottom: -30px;
							display: block;
							height: 12px;
							width: 12px;
							left: 50%;
							position: absolute;
							transform: rotate(45deg) translateX(-50%);
						}
					}


					&:last-child {
						&:after {
							display: none;
						}
					}

					&:nth-child(even) {
						margin-right: 0;
						margin-left: calc(50% + 75px);

						@include mq(tablet-down) {
							margin-left: 0;
						}


						.item-marker {
							right: auto;
							left: -91px;
						}

						&.mod-active .item-marker {
							left: -97px;
						}

						.wrap-content:before {
							left: auto;
							right: 100%;
							border-left-color: rgba(0, 0, 0, 0);
							border-right-color: #ddddd9;

							@include mq(tablet-down) {
								display: none;
							}
						}
					}

					.item-marker {
						content: "";
						background: $color-white;
						border-radius: 50%;
						border: 1px solid $color-primary;
						box-sizing: content-box;
						height: 30px;
						position: absolute;
						right: -91px;
						top: 50px;
						width: 30px;

						&:before {
							background: $color-white;
							border-radius: 50%;
							border: 1px solid $color-primary;
							content: '';
							display: block;
							height: 18px;
							left: 50%;
							position: absolute;
							top: 50%;
							transform: translate(-50%, -50%);
							width: 18px;
						}

						@include mq(tablet-down) {
							display: none;
						}
					}

					&.mod-active .item-marker {
						width: 12px;
						height: 12px;
						border: 16px solid $color-black;
						top: 39px;
						right: -97px;
					}

					.wrap-content {
						padding: 12px 12px 30px;
						background: #ddddd9;
						position: relative;

						&:before {
							top: 60px;
							border: solid transparent;
							content: "";
							height: 0;
							width: 0;
							position: absolute;
							pointer-events: none;
							border-color: rgba(0, 0, 0, 0);
							left: 100%;
							border-left-color: #ddddd9;
							border-width: 12px;
							margin-top: -5px;

							@include mq(tablet-down) {
								display: none;
							}
						}
					}

					.sub-image {
						background-size: cover;
						background-repeat: no-repeat;
						height: 123px;
						margin-bottom: 20px;
					}

					.sub-title {
						padding: 0 9px;

						h4 {
							margin-bottom: 0;
							font-size: 28px;
							color: $color-primary;
							max-width: 220px;
							margin-bottom: 22px;
							width: 100%;

							@include mq(tablet-down) {
								font-size: 20px;
								max-width: none;
								margin-bottom: 15px;
								text-align: center;
							}
						}
					}

					.sub-content {
						color: #4A4A4A;
						font-size: $font-size-med;
						line-height: 24px;
						padding: 0 9px;

						@include mq(tablet-down) {
							font-size: $font-size-standard;
							text-align: center;
						}


						p:last-of-type {
							margin-bottom: 0;
						}
					}
				}
			}
		}

		.sub-more {
			@extend %container;

			margin-top: 275px;
			max-width: 700px !important;
			text-align: center;
			font-size: $font-size-large;
			color: $color-white;

			@include mq(tablet-down) {
				margin-top: 25px;
				font-size: $font-size-med;
			}


			h3 {
				font-size: $font-size-largest / $font-size-large + em;
				font-weight: $font-weight-bold;
				margin-bottom: 40px;
				color: $color-white;

				@include mq(tablet-down) {
					margin-bottom: 30px;
					font-size: $font-size-large / $font-size-med + em;
				}
			}
		}
	}
}
