#contact {
	font-family: $font-standard;

	.section-contact {
		display: flex;

		@include mq(tablet-down) {
			flex-wrap: wrap;
		}


		.aside-contact-details {
			background-color: $color-secondary;
			padding: 60px 8% 60px 8%;
			width: calc(100% / 3);

			@include mq(tablet-down) {
				background-color: transparent;
				padding: 0;
				width: 100%;
			}


			.sub-copy-mobile {
				display: none;

				@include mq(tablet-down) {
					background-color: $color-secondary;
					display: block;
					padding: 0 24px 50px;

					h2 {
						font-size: $font-size-standard;
						font-weight: $font-weight-regular;
						color: $color-black;
						line-height: 32px;
					}
				}
			}

			& > h2 {
				color: $color-text-title;
				font-size: 25px;
				font-weight: $font-weight-bold;
				letter-spacing: 0.6px;
				margin-bottom: 100px;
				max-width: 512px;
				width: 100%;

				@include mq(tablet-down) {
					background-color: $color-secondary;
					font-size: 20px;
					margin-bottom: 0;
					padding: 45px 24px 20px 24px;
					max-width: none;
				}
			}

			ul.list-contact-details {
				margin: 0 0 65px 0;
				list-style-type: none;

				@include mq(tablet-down) {
					margin-bottom: 25px;
					padding: 35px 24px 0;
				}


				& > li {
					margin-left: 0;

					a {
						color: $color-primary;
						display: flex;
						font-size: 22px;
						margin-bottom: 10px;

						@include mq(tablet-down) {
							font-size: $font-size-large;
							margin-bottom: 3px;
						}


						span {
							display: block;
							font-weight: $font-weight-light;
							margin-right: 9px;
							width: 15px;
						}
					}
				}
			}

			ul.list-contact-address {
				margin: 0 0 65px 0;
				list-style-type: none;

				@include mq(tablet-down) {
					margin-bottom: 35px;
					padding: 0 24px;
				}


				& > li {
					color: $color-primary;
					font-size: 19px;
					margin-left: 0;

					@include mq(tablet-down) {
						font-size: $font-size-standard;
					}
				}
			}

			ul.list-social-links {
				@extend %list-default;

				display: flex;

				@include mq(tablet-down) {
					padding: 0 24px;
				}


				& > li {
					margin-right: 13px;

					@include mq(tablet-down) {
						margin-right: 18px;
					}


					a {
						display: block;
						height: 100%;

						svg {
							color: $color-primary;
							height: 24px;
							width: 34px;

							&.icon-instagram {
								width: 25px;
							}

							&.icon-facebook {
								width: 14px;
							}
						}
					}

					&:last-child {
						margin-right: 0;
					}
				}
			}
		}

		.section-form {
			padding: 60px 0;
			margin-left: 70px;
			max-width: 568px;
			width: 100%;

			@include mq(tablet-down) {
				margin-left: 0;
				max-width: none;
				padding: 50px 24px;
				width: 100%;
			}


			form {
				@extend %container;

				@include mq(tablet-down) {
					max-width: none;
					margin-bottom: 40px;
					width: 100%;
				}


				.sub-form-copy {
					@include mq(tablet-down) {
						display: none;
					}
				}

				.form-element {
					select {
						-moz-appearance: none;
						-webkit-appearance: none;
						background: transparent;
						border: none;
						border-bottom: 1px solid #c8c8c8;
						color: #03303b;
						display: block;
						font-size: 16px;
						font-weight: 300;
						outline: none;
						padding: 27px 0 20px;
						position: relative;
						text-indent: 1px;
						text-overflow: '';
						width: 100%;
						z-index: 2;

						@include mq(tablet-down) {
							font-size: $font-size-standard;
							text-indent: 0px;
						}
					}

					label {
						color: $color-primary;
						font-size: 16px;
						font-weight: $font-weight-light;

						@include mq(tablet-down) {
							font-size: $font-size-standard;
						}
					}

					.carat-icon--down {
						bottom: 26px;
						height: 9px;
						left: 145px;
						position: absolute;
						width: 15px;

						g {
							stroke: $color-text-title;
						}
					}
				}

				.sub-errors {
					color: $color-error;
					display: none;
					margin-bottom: 20px;

					.sub-error {
						font-size: $font-size-med;
					}

					span {
						display: block;
						margin-bottom: 3px;
					}
				}

				h2 {
					font-size: 19px;
					font-weight: $font-weight-regular;
					line-height: 30px;
					margin-bottom: 80px;

					@include mq(tablet-down) {
						font-size: $font-size-larger;
					}
				}

				.btn-primary {
					background: $color-white;
					border: 2.225px solid #d4d9db;
					border-radius: 5px;
					color: $color-primary;
					justify-content: center;
					margin: 73px 0 39px 0;
					padding: 15px 66px;
					text-align: center;
					text-transform: uppercase;
					width: auto;

					&:hover {
						color: rgba($color-primary, 0.5);
					}

					@include mq(tablet-down) {
						font-size: $font-size-small;
						margin: 60px auto 0;
					}
				}
			}

			.wrap-thanks {
				font-size: $font-size-larger;
				display: none;
			}
		}
	}

	.section-map {
		#map {
			height: 500px;

			@include mq(tablet-down) {
				height: 430px;
			}
		}
	}
}
