#news-post {
	.section-hero {
		padding: 55px 0;

		@include mq(tablet-down) {
			padding: 35px 0;
		}


		.wrap-hero {
			@extend %container;

			display: flex;
			flex-direction: row;
			justify-content: space-between;
			position: relative;

			@include mq(tablet-down) {
				flex-wrap: wrap;
			}


			.sub-copy {
				border-bottom: 2px solid #D8D8D8;
				max-width: 547px;
				width: 547px;

				@include mq(tablet-down) {
					width: 100%;
				}


				h3 {
					color: $color-text;
					font-size: $font-size-standard;
					margin: 0 0 30px;

					@include mq(tablet-down) {
						display: none;
					}
				}

				h1 {
					margin: 0 0 20px;

					@include mq(tablet-down) {
						font-size: $font-size-h2;
					}
				}

				p {
					font-size: $font-size-large;
					line-height: $line-height-large;
					margin: 0;
				}

				.sub-title {
					h1 {
						color: #00303C;
						font-size: 25px;
						font-weight: $font-weight-bold;
					}
				}

				.sub-date-mob {
					display: none;

					h5 {
						margin: 0 20px 0 0;
						font-size: $font-size-med;
					}

					@include mq(tablet-down) {
						display: inline-block;
					}
				}
			}
		}
	}

	.section-hero-image {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 403px;
	}

	.section-post {
		padding: 0 0 70px;

		@include mq(tablet-down) {
			padding: 10px 0 70px;
		}


		.sub-content {
			@extend %container, %clearfix;

			.sub-copy {
				max-width: 785px;

				p {
					font-size: $font-size-med;
					line-height: $line-height-large;
				}
			}
		}
	}

	.section-related.post-page {
		padding: 60px 0;

		@include mq(tablet-down) {
			padding: 60px 0 80px;
		}


		.wrap-related {
			@extend %container;
		}

		h2 {
			margin: 0 0 55px;
			color: #00303C;
			font-size: 25px;
			font-weight: $font-weight-bold;

			@include mq(tablet-down) {
				margin: 0 0 40px;
			}
		}

		.list-more-news {
			margin-left: 0;

			.item-news {
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				height: 277px;
				margin: 0 0 0 -1px;
				width: 395px;
			}
		}
	}
}
