#project {
	.section-project-hero {
		height: 754px;
		width: 100%;

		@include mq(tablet-down) {
			height: calc(100vh - 61px);
		}


		.list-project-hero-images {
			height: 100%;
			list-style-type: none;
			margin: 0;

			.item-project-hero-image {
				background-position: center;
				background-size: cover;
				display: flex;
				height: 754px;
				margin: 0;
				width: 100vw !important;
			}

			.slick-dots {
				background-color: rgba(255, 255, 255, 0.5);
				position: absolute;
				bottom: 0;
				display: flex;
				width: 100%;

				li {
					cursor: pointer;
					flex: 1;
					flex-shrink: 0;
					height: 7px;
					margin: 0;
					padding: 0;

					@include mq(tablet-down) {
						height: 10px;
					}


					button {
						background: rgba($color-white, 0.8);
						border: none;
						border-radius: 0;
						text-decoration: none;
						text-indent: -99999px;
						width: 100%;
						padding: 0;
						height: 7px;
						transition: background .2s ease;
						outline: none;
						cursor: pointer;

						@include mq(tablet-down) {
							height: 10px;
						}
					}

					n mbhy8u
					&.slick-active button {
						background-color: $color-primary;
						opacity: 0.8;
					}

					&.slick-active button:hover {
						background-color: $color-primary;
						opacity: 0.8;
					}

					button:hover {
						background-color: $color-primary;
						opacity: 0.4;
					}
				}
			}

			.slick-list {
				height: 100%;
				padding: 0;
			}
		}
	}

	.section-main-content {
		margin: 120px 0;

		@include mq(tablet-down) {
			margin: 40px 0 80px;
		}


		.sub-content {
			@extend %container;

			display: flex;

			@include mq(tablet-down) {
				flex-direction: column;
			}


			.sub-content-left {
				padding-right: 10%;
				width: 55.5%;

				@include mq(tablet-down) {
					padding-right: 0;
					width: 100%;
				}


				p {
					color: #5B6464;
					font-size: 13px;
					font-weight: $font-weight-regular;
					letter-spacing: 1.3px;
					margin-bottom: 13px;
					text-transform: uppercase;

					@include mq(tablet-down) {
						font-size: 10px;
					}
				}

				h2 {
					color: #00303c;
					font-size: 25px;
					font-weight: $font-weight-bold;
					letter-spacing: .6px;
					margin-bottom: 30px;
					max-width: 512px;
					width: 100%;

					@include mq(tablet-down) {
						font-size: 20px;
						margin-bottom: 20px;
					}
				}

				.info-wrapper {
					border-bottom: 1px solid #D8D8D8;
					border-top: 1px solid #D8D8D8;
					margin-bottom: 30px;
					padding: 30px 0;

					p {
						font-size: 16px;
						text-transform: capitalize;

						@include mq(tablet-down) {
							font-size: $font-size-standard;
						}
					}

					p:nth-last-child(1) {
						margin-bottom: 0;
					}
				}

				.sub-image-mobile {
					display: none;

					@include mq(tablet-down) {
						background-size: cover;
						background-position: center center;
						display: block;
						height: 196px;
						margin-bottom: 30px;
						width: 100%;
					}
				}

				.main-copy {
					p {
						color: $color-black;
						font-size: $font-size-med;
						font-weight: $font-weight-regular;
						letter-spacing: .6px;
						line-height: 33px;
						text-transform: unset;

						@include mq(tablet-down) {
							font-size: $font-size-standard;
						}
					}
				}
			}

			.sub-content-right {
				background-position: center;
				background-size: cover;
				background-repeat: no-repeat;
				height: 461px;
				width: 44.5%;

				@include mq(tablet-down) {
					display: none;
				}
			}
		}
	}

	.section-more-projects {
		margin-bottom: 180px;

		.sub-content {
			left: 664px;
			position: relative;
			overflow: hidden;

			@include mq(tablet-down) {
				overflow: hidden;
			}


			h2 {
				color: $color-text-title;
				font-size: 25px;
				font-weight: $font-weight-bold;
				letter-spacing: 0.46px;
			}

			h5 {
				color: $color-white;
				font-size: 20px;
				font-style: normal;
				font-weight: $font-weight-semibold;
				letter-spacing: 1.6px;

				@include mq(tablet-down) {
					font-size: 18px;
					margin-bottom: 13px;
				}
			}

			.list-more-projects {
				margin: 0;
				list-style-type: none;

				@include mq(tablet-down) {
					display: flex;
					flex-wrap: wrap;
				}


				.slick-prev, .slick-next {
					background-color: green;
					color: $color-text-title;
					cursor: pointer;
					font-size: 24px;
					position: absolute;
					top: -43px;
				}

				.slick-next {
					left: 350px;
				}

				.slick-prev {
					left: 310px;
				}

				.slick-disabled {
					cursor: default;
					opacity: 0.5;
				}

				.slick-slide {
					width: 395px;
				}

				.item-project {
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
					height: 277px;
					margin: 0 0 0 -1px;

					@include mq(tablet-down) {
						height: 196px;
						background-size: cover;
						background-position: center;
						background-repeat: no-repeat;
					}


					.item-information-overlay {
						background-color: $color-primary;
						display: none;
						flex-direction: column;
						height: 100%;
						margin: 0 0 0 -1px;
						opacity: 0.8;
						padding: 30px 40px;
						position: absolute;
						width: 395px;

						@include mq(tablet-down) {
							display: block;
						}


						.project-info-sub-title {
							color: $color-white;
							font-size: 13px;
							letter-spacing: 1.3px;
							margin-bottom: 5px;
							text-transform: uppercase;
						}

						p {
							justify-self: flex-end;
							color: $color-white;
							font-size: 16px;
							line-height: 29px;
						}

						h5 {
							color: $color-white;
							font-size: 19px;
							font-weight: $font-weight-bold;
							letter-spacing: 0.8px;
						}
					}

					.mod-show {
						display: flex;
					}
				}
			}

			.sub-button-mobile {
				display: none;

				@include mq(tablet-down) {
					border: 1px solid #a7aba1;
					display: block;
					font-size: 12px;
					font-weight: $font-weight-bold;
					letter-spacing: 0.6px;
					margin: 53px auto;
					padding: 15px;
					text-align: center;
					text-transform: uppercase;
					width: 70%;
				}
			}
		}
	}
}
